import logo from '../img/feather.png';
import classes from './IntroView.module.css';

const IntroView = () => {
  return (
    <section>
      <div className={classes['intro-container']}>
        <h1>
          Christopher <span>Jaeger</span>
        </h1>
        <p className={classes.subtitle}>Front-End Web Developer</p>
        <img src={logo} alt="Logo" />
      </div>
    </section>
  );
};

export default IntroView;
