const skillsList = [
  {
    name: 'Javascript',
    display: '',
    pop: false,
  },
  {
    name: 'SQL',
    display: 'small',
    pop: false,
  },
  {
    name: 'Redux',
    display: '',
    pop: false,
  },
  {
    name: 'HTML',
    display: '',
    pop: false,
  },
  {
    name: 'Git',
    display: 'small',
    pop: false,
  },
  {
    name: 'Wordpress',
    display: '',
    pop: false,
  },
  {
    name: 'React',
    display: '',
    pop: false,
  },
  {
    name: 'CSS',
    display: 'small',
    pop: false,
  },
  {
    name: 'npm',
    display: '',
    pop: false,
  },
  {
    name: 'PHP',
    display: 'small',
    pop: false,
  },
];

export default skillsList;
