import React, { useState, useRef, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import projects from '../data/projects';
import icons from '../img/icons.svg';
import classes from './ProjectsView.module.css';

const ProjectsView = () => {
  const [projectSelected, setProjectSelected] = useState(() =>
    projects.map((__, _i) => false)
  );
  const [displayProject, setDisplayProject] = useState({
    display: false,
    projectId: 0,
  });
  const selectedProject = projects[displayProject.projectId];
  const windowHeight = window.innerHeight;
  const isMobile = window.innerWidth < 400;
  const projectsContainerHeight = 300 * (projects.length / 2);
  const projectContainerRef = useRef();
  const projectListRef = useRef();

  const getProjectList = () => {
    const projectItems = projects.map((project, _i) => {
      const itemRef = React.createRef();
      return (
        <CSSTransition
          key={`project${_i}`}
          in={projectSelected[_i]}
          timeout={300}
          nodeRef={itemRef}
          classNames={{
            enterActive: classes['hover'],
            enterDone: classes['selected'],
          }}
        >
          <li
            className={classes.item}
            onMouseEnter={projectMouseOverHandler}
            onClick={projectClickedHandler}
            data-index={_i}
            data-show="false"
            ref={itemRef}
          >
            <img
              className={classes.image}
              alt={project.title}
              src={project.imageURL}
            />
          </li>
        </CSSTransition>
      );
    });
    return projectItems;
  };

  const projectMouseOverHandler = e => {
    const changedProjectList = projectSelected.map(project => false);
    const projectIndex = e.target.closest('li').dataset.index;
    changedProjectList[projectIndex] = true;
    setProjectSelected(changedProjectList);
  };

  const projectClickedHandler = e => {
    if (isMobile) {
      projectContainerRef.current.style.height = '800px';
    }
    window.scrollTo({
      top: windowHeight * 2,
      behavior: 'smooth',
    });
    const projectIndex = e.target.closest('li').dataset.index;
    setDisplayProject({
      display: true,
      projectId: projectIndex,
    });
  };

  const closeClickedHandler = () => {
    if (isMobile) {
      projectContainerRef.current.style.height = `${projectsContainerHeight}px`;
    }
    setDisplayProject(prevState => ({
      ...prevState,
      display: false,
    }));
  };

  useEffect(() => {
    if (isMobile) {
      projectContainerRef.current.style.height = `${projectsContainerHeight}px`;
    }
  }, [isMobile, projectsContainerHeight]);

  return (
    <section className={classes.projects} ref={projectContainerRef}>
      <div className={classes['projects-container']}>
        <div className={classes.header}>
          <h1>
            Project<span>s</span>
          </h1>
          {displayProject.display && (
            <>
              <p className={classes.subtitle}>
                <img src={selectedProject.icon} alt="Project Icon" />
                <span className={classes.maintitle}>
                  {selectedProject.title}
                </span>{' '}
                <span className={classes.shortdesc}>
                  - {selectedProject.shortDesc}
                </span>
                <span className={classes['skills-list']}>
                  {selectedProject.skills.map((skill, _i) => (
                    <svg key={`skill${_i}`}>
                      <use href={`${icons}#${skill}-icon`}></use>
                    </svg>
                  ))}
                </span>
              </p>
              <div className={classes.description}>
                <p>PROJECT INFO</p>
                <h2>{selectedProject.description}</h2>
                <div className={classes.close} onClick={closeClickedHandler}>
                  CLOSE
                </div>
              </div>
            </>
          )}
          {!displayProject.display && (
            <p className={classes.subtitle}>Check out my other projects</p>
          )}
        </div>
        <div className={classes['projects-list']}>
          <div className={classes['project-display']}>
            <div className={classes['project-details']}>
              <ul>
                {selectedProject.features.map((feature, _i) => (
                  <li key={`feature${_i}`}>
                    <span>
                      <svg>
                        <use href={`${icons}#icon-check`}></use>
                      </svg>
                    </span>
                    {feature}
                  </li>
                ))}
              </ul>
              <a
                href={selectedProject.siteURL}
                target="_blank"
                rel="noreferrer"
                className={classes['view-website']}
              >
                View Website
                {!selectedProject.mobileFriendly && (
                  <span className={classes['mobile-friendly']}>
                    *Not mobile friendly
                  </span>
                )}
              </a>
            </div>
            <img src={selectedProject.imageURL} alt={selectedProject.title} />
          </div>
          <CSSTransition
            in={displayProject.display}
            timeout={300}
            nodeRef={projectListRef}
            classNames={{
              enterActive: classes['hide'],
              enterDone: classes['hidden'],
            }}
          >
            <ul className={classes['project-list']} ref={projectListRef}>
              {getProjectList()}
            </ul>
          </CSSTransition>
        </div>
      </div>
    </section>
  );
};

export default ProjectsView;
