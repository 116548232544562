import classes from './ContactView.module.css';

const ContactView = () => {
  const email = ['contact', '@', 'christopherjaeger.com'];

  const onClickHandler = () => {
    const emailAddress = `${email[0]}${email[1]}${email[2]}`;
    window.location.href = `mailto:${emailAddress}`;
  };
  return (
    <section className={classes.contact}>
      <div className={classes['contact-container']}>
        <h1>
          Contact <span>me</span>
        </h1>
        <p className={classes.subtitle} onClick={onClickHandler}>
          <span>contact</span>@christopherjaeger.com
        </p>
      </div>
    </section>
  );
};

export default ContactView;
