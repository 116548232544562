import { CSSTransition } from 'react-transition-group';
import React, { useState, useRef, useEffect } from 'react';
import skillsList from '../data/skills';
import classes from './SkillsView.module.css';

const SkillsView = props => {
  const page = props.currentPage;
  const [showSkillTree, setShowSkillTree] = useState(false);
  const [skillTree, setSkillTree] = useState(skillsList);
  const yearsOfExperience = new Date().getFullYear() - 2014;
  const skillTreeRef = useRef();

  const getSkillTree = () => {
    const skills = [[], []];
    skillsList.forEach((skill, index) => {
      const skillRef = React.createRef();
      let listNumber = 0;
      if (index > 5) listNumber = 1;

      skills[listNumber].push(
        <CSSTransition
          key={`skill${index}`}
          in={skillTree[index].pop}
          nodeRef={skillRef}
          timeout={500}
          classNames={{
            enterActive: classes['pop'],
            enterDone: classes['pop'],
          }}
        >
          <div
            className={`${classes.skill} ${classes[skill.display]}`}
            ref={skillRef}
          >
            {skill.name}
          </div>
        </CSSTransition>
      );
    });

    return (
      <CSSTransition
        in={showSkillTree}
        timeout={1000}
        nodeRef={skillTreeRef}
        classNames={{
          enterActive: classes['show'],
          enterDone: classes['show'],
        }}
      >
        <div className={classes['bg--circle--outer']} ref={skillTreeRef}>
          {skills[0]}
          <div className={classes['bg--circle--inner']}>{skills[1]}</div>
        </div>
      </CSSTransition>
    );
  };

  useEffect(() => {
    if (!showSkillTree && page === 2) {
      setTimeout(() => {
        setShowSkillTree(true);
      }, 250);
    }
    if (showSkillTree) {
      setTimeout(() => {
        for (let i = 0; i < skillTree.length; i++) {
          if (skillTree[i].pop === false) {
            const newSkillTree = [...skillTree];
            newSkillTree[i].pop = true;
            setSkillTree(newSkillTree);
            return;
          }
        }
      }, 100);
    }
  }, [showSkillTree, skillTree, page]);

  return (
    <section className={classes.skills}>
      <div className={classes['skills-container']}>
        <div className={classes.skills}>{getSkillTree()}</div>
        <div className={classes.textarea}>
          <h1>
            Skills <span>&</span> Experience
          </h1>
          <p className={classes.subtext}>
            My journey in web development began {yearsOfExperience} years ago
            making Wordpress sites for small & medium businesses.
          </p>
          <p>
            While expanding my knowledge of Front-End development, HTML, CSS and
            Javascript, I have focused my learning on best practices, the core
            principles of ES6+ and Javascript design patterns.
          </p>
          {/* <p>
            The next step of my journey delves into the world of React. I am
            also working on server-side language and tools such as Python and
            NodeJS, with the ultimate goal of becoming a Full Stack developer.
          </p> */}
        </div>
      </div>
    </section>
  );
};

export default SkillsView;
