import { useState } from 'react';
import IntroView from './components/IntroView';
import WebsiteTemplateView from './components/WebsiteTemplateView';
import ProjectsView from './components/ProjectsView';
import SkillsView from './components/SkillsView';
import ContactView from './components/ContactView';
import ScrollBar from './components/ScrollBar';

function App() {
  const [page, setPage] = useState(0);

  const newPageHandler = newPage => setPage(newPage);

  return (
    <div className="main-container">
      <IntroView />
      <WebsiteTemplateView currentPage={page} />
      <ProjectsView currentPage={page} />
      <SkillsView currentPage={page} />
      <ContactView />
      <ScrollBar onNewPage={newPageHandler} />
    </div>
  );
}

export default App;
