import adminiImage from '../img/project--admini.png';
import promanagerImage from '../img/project--promanager.png';
import wordWarsImage from '../img/project--wordwars.png';
import recipeImage from '../img/project--recipe.png';
import maptyImage from '../img/project--mapty.png';
import adminiIcon from '../img/project--admini--icon.png';
import promanagerIcon from '../img/project--promanager--icon.png';
import wordwarsIcon from '../img/project--wordwars--icon.png';
import recipeIcon from '../img/project--recipe--icon.png';
import maptyIcon from '../img/project--mapty--icon.png';

const projects = [
  {
    title: 'Admini app',
    imageURL: adminiImage,
    siteURL: `https://admini-cjaeger.netlify.app`,
    shortDesc: 'Admin panel',
    description: 'A company management admin panel',
    features: [
      'Dashboard overview',
      'Manage users',
      'Manage sales',
      'Track data & charts',
      'Track events in Calendar',
      'Built using React',
    ],
    skills: ['react', 'js', 'npm'],
    icon: adminiIcon,
    mobileFriendly: false,
  },
  {
    title: 'ProManager app',
    imageURL: promanagerImage,
    siteURL: `https://promanager-cjaeger.netlify.app`,
    shortDesc: 'Project manager',
    description: 'An app to manage projects & related tasks',
    features: [
      'Create projects',
      'Manage tasks',
      'Track progress',
      'Add comments',
      'Sort, filter and search through tasks',
      'Built using React',
    ],
    skills: ['react', 'js', 'npm'],
    icon: promanagerIcon,
    mobileFriendly: true,
  },
  {
    title: 'WordWars app',
    imageURL: wordWarsImage,
    siteURL: `https://wordwars-cjaeger.netlify.app`,
    shortDesc: 'Typing game',
    description: 'A game that challenges your typing speed',
    features: [
      'Dynamically fetches texts from an API',
      'Challenge your typing speed with a timer',
      'Calculates your WPM (words typed per minute)',
      'Saves your Highscores locally',
      'Built using React',
    ],
    skills: ['react', 'js', 'npm'],
    icon: wordwarsIcon,
    mobileFriendly: false,
  },
  {
    title: 'Forkify app',
    imageURL: recipeImage,
    siteURL: `https://forkify-cjaeger.netlify.app`,
    shortDesc: 'Recipe tracker',
    description: 'An app that searches, bookmarks, and creates new recipes',
    features: [
      'Search for recipes',
      'Bookmark recipes',
      'Add your own recipes',
      'Adjust serving size',
      'Create a shopping list of ingredients',
      'Uses MVC design principles',
    ],
    skills: ['js', 'sass', 'npm'],
    icon: recipeIcon,
    mobileFriendly: false,
  },
  {
    title: 'Mapty app',
    imageURL: maptyImage,
    siteURL: 'https://mapty-cjaeger.netlify.app',
    shortDesc: 'Workout tracker',
    description:
      'An app that integrates the Leaflet API to track your running & cycling workouts',
    features: [
      'Click on map to record your running & cycling workouts',
      'Reverse geocodes the location of your workout',
      'Records the temperature of each workout location',
      'Calculates your Steps Per Minute or km/h',
    ],
    skills: ['js'],
    icon: maptyIcon,
    mobileFriendly: false,
  },
];

export default projects;
