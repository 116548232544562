import { useEffect, useState, useCallback, useRef } from 'react';
import classes from './ScrollBar.module.css';

const ScrollBar = props => {
  const [navSelected, setNavSelected] = useState(0);
  const setNewPage = props.onNewPage;
  const sectionsAmount = 5;
  const windowHeight = window.innerHeight;
  const navPointerRef = useRef();

  const scrollHandler = useCallback(() => {
    const offset = window.pageYOffset + windowHeight / 2;
    const page = Math.trunc(offset / windowHeight);
    if (page !== navSelected) {
      if (navPointerRef.current.dataset.scrolling === 'false') {
        navPointerRef.current.dataset.location = page;
      }
      setNewPage(page);
      setNavSelected(page);
    }
  }, [setNewPage, navSelected, windowHeight]);

  const locationClickHandler = e => {
    const location = e.target.dataset.section;
    const scrollPos = location * windowHeight;
    navPointerRef.current.dataset.location = location;
    navPointerRef.current.dataset.scrolling = 'true';
    window.scrollTo({
      top: scrollPos,
      behavior: 'smooth',
    });
    setTimeout(() => {
      navPointerRef.current.dataset.scrolling = 'false';
    }, 500);
  };

  const generateScrollBarLinks = () => {
    const links = [];
    for (let i = 0; i < sectionsAmount; i++) {
      links.push(
        <div
          key={`navlocation${i}`}
          className={`${classes['nav-location']} ${
            i === navSelected ? classes.selected : ''
          }`}
          data-section={i}
          onClick={locationClickHandler}
        ></div>
      );
    }
    return links;
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [scrollHandler]);

  return (
    <div className={classes['nav-scroller']}>
      <div
        className={classes['nav-pointer']}
        data-location="0"
        data-scrolling="false"
        ref={navPointerRef}
      ></div>
      {generateScrollBarLinks()}
    </div>
  );
};

export default ScrollBar;
